import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import AdjustItem from "./AdjustItem";
import { formatPrice } from "../../utils/money";
import { CartContext } from "../../context/CartContext";
import Loader from "../form/Loader";
import { productQtyMessages } from "../../utils/common";

import { useSelector } from "react-redux";
import { checkCustomerGroup } from "../products/Price";
import Image from "../common/Image";

const parseURL = (url) => {
  if (url) {
    if (url?.includes(".com")) {
      return url.split(".com")[1];
    } else {
      return url;
    }
  }
};

const ItemRow = ({
  item,
  isInventoryLow,
  removeCartItem,
  cartType,
  register,
}) => {
  const { removing } = useContext(CartContext);
  const { currentLoginUser } = useSelector((state) => state.customer);

  const currentUserData = checkCustomerGroup(currentLoginUser);

  return (
    <div
      className={
        item.inventory === 0
          ? "item-zero-inventory bc-cart-item "
          : item.quantity < item.order_quantity_minimum &&
            item.order_quantity_minimum !== 0
            ? "item-less-minpurchaseqty bc-cart-item"
            : item.quantity > item.order_quantity_maximum &&
              item.order_quantity_maximum !== 0
              ? `item-less-minpurchaseqty bc-cart-item`
              : item.inventory < item.quantity
                ? "item-less-inventory bc-cart-item "
                : "bc-cart-item "
      }
      key={item.variant_id}
    >
      <div className="cart-item-mark cart-col-mark cart-col-1">
        <label className="form-label form-label-facet">
          <input
            type="checkbox"
            className="form-checkbox-facet"
            {...register("skus", [])}
            value={item.sku}
          />
          {/* Remove Item */}
          <span className="checkbox-icon"></span>
        </label>
      </div>

      {cartType === "full" ? (
        <div className="bc-cart-item-image cart-col-image cart-col-2">
          <div className="cart-img-inner">
            <Link to={`${parseURL(item.url)}`}>
              <Image
                src={
                  item.image_url
                    ? item.image_url
                    : "/img/default-bc-product.png"
                }
                alt={`${item.name}`} />
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="bc-cart-item-meta cart-col-name cart-col-3">
        {item?.brand_name ? (
          <p className="cart-brand-name">{item.brand_name}</p>
        ) : null}
        <h3 className="bc-cart-item__product-title">
          <Link to={`${parseURL(item.url)}`}>{item.name}</Link>
        </h3>

        {item?.options?.length
          ? item.options.map((i, index) => {
            const entries = Object.entries(i);
            const data = entries.map(([key, val]) => {
              return (
                <p className="cart-item-option-text" key={`${key}-${val}`}>
                  <strong>{`${key}`} :</strong>
                  <span>{`${val}`}</span>
                </p>
              );
            });
            return data;
          })
          : null}

        <p className="cart-item-option-text">
          <strong className="">{`SKU - ${item.sku}`}</strong>
        </p>
        {item.inventory === 0 && (
          <div className="bc-cart-item-less-quantity-error">
            <p>{productQtyMessages("zero", 0)}</p>
          </div>
        )}

        {item.inventory < item.quantity && item.inventory !== 0 && (
          <div className="bc-cart-item-less-quantity-error">
            <p>{productQtyMessages("less-qty", 0)}</p>
          </div>
        )}

        {item.inventory !== 0 && item.order_quantity_minimum !== 0 && (
          <div className="bc-cart-item-less-quantity-error">
            <p>{productQtyMessages("minimum", item.order_quantity_minimum)}</p>
          </div>
        )}

        {item.inventory !== 0 && item.order_quantity_maximum !== 0 && (
          <div className="bc-cart-item-less-quantity-error">
            <p> {productQtyMessages("maximum", item.order_quantity_maximum)}</p>
          </div>
        )}
      </div>

      <div className="bc-cart-item-single-price cart-col-price cart-col-4">
        <div className="cart-item-inner">
          <p className="cart-item-lable">Price</p>
          <div>
            <div
              className={`customer-badge-section flex vertical-middle ${currentUserData?.className}`}
            >
              <div className="customer-badge-icon">
                <Image src={currentUserData?.icon} />

              </div>
              <div className="customer-badge-name">{currentUserData?.type}</div>
              <div className="customer-badge-id">{currentLoginUser?.id}</div>
            </div>

            <div className="price-wrapper">
              <div className="customer-badge-id-overlay">
                <div>{currentLoginUser?.id}</div>
              </div>
              <div className="cart-price-wrapper">
                {formatPrice(item.sale_price)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdjustItem
        item={item}
        cartType={cartType}
        isInventoryLow={isInventoryLow}
      />

      <div className="bc-cart-item-total-price cart-col-tprice cart-col-6">
        <div className="cart-item-inner">
          <p className="cart-item-lable">Total Price</p>
          <div className="flex vertical-middle justify-space">
            <div className="cart-total-price">
              {formatPrice(item.extended_sale_price)}
            </div>

            <div className="cart-item-action ">
              {removing === item.sku ? (
                <button
                  className="bc-link bc-cart-item__remove-button cart-remove-loading"
                  onClick={() => removeCartItem(item.sku)}
                  type="button"
                >
                  <Loader />
                  &nbsp;
                </button>
              ) : (
                <button
                  className="bc-link bc-cart-item__remove-button"
                  onClick={() => removeCartItem(item.sku)}
                  type="button"
                >
                  &nbsp;
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function StandardItems({
  lineItems,
  cartType,
  register,
  lowerInventoryLineItems,
}) {
  const { removeCartItem } = useContext(CartContext);

  return (
    <>
      {lowerInventoryLineItems.map((item) => {
        return (
          <ItemRow
            key={item.variant_id}
            item={item}
            isInventoryLow={true}
            removeCartItem={removeCartItem}
            cartType={cartType}
            register={register}
          />
        );
      })}
      {lineItems.map((item) => {
        return (
          <ItemRow
            key={item.variant_id}
            item={item}
            isInventoryLow={false}
            removeCartItem={removeCartItem}
            cartType={cartType}
            register={register}
          />
        );
      })}
    </>
  );
}

StandardItems.propTypes = {
  lineItems: PropTypes.array,
  lowerInventoryLineItems: PropTypes.array,
  cartType: PropTypes.string,
};

export default StandardItems;
