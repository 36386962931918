import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import useCart from "../../hooks/cart/useCart";
import Button from "../../components/form/button/Button";
import CartFooter from "../../components/cart/CartFooter";
import StandardItems from "../../components/cart/StandardItems";
import CartHeader from "../../components/cart/CartHeader";
import CartMessage from "../../components/cart/CartMessage";
import Loader from "../../components/form/Loader";
import { CartContext } from "../../context/CartContext";
import {
  LINE_ITEM_FIXED_LIMIT,
  LINE_ITEM_WARNING_MESSAGE,
} from "../../utils/common";

const Cart = ({ cartType }) => {
  // retrieve cart and cart-related functions using useCart hook
  const { cart, lowerInventoryLineItems, lineItems, isLoading } = useCart();
  const { lineItemsLength } = useSelector((state) => state.cart);
  // retrieve removeMultipleCartItems function from CartContext
  const { removeMultipleCartItems } = useContext(CartContext);

  // retrieve user from redux state
  const { user } = useSelector((state) => state.auth);

  // initialize useForm hook to manage form data
  const { register, getValues } = useForm();

  // remove items from cart based on selected skus
  const removeItems = () => {
    removeMultipleCartItems(
      typeof getValues("skus") === "string"
        ? [getValues("skus")]
        : getValues("skus")
    );
  };

  useEffect(() => {
    // Disable the back button
    window.history.pushState(null, null, window.location.href);
  }, []);

  return (
    <>
      <section className="bc-cart">
        {/* page heading */}
        <div className="page-heading-section">
          <h1 className="page-title remove-border">Your Cart</h1>

          {/* cart actions section */}
          {lineItemsLength > 0 && (
            <div className="cart-top-action-section">
              <div className="bc-cart-actions">
                <Link className="button button-small button-border" to={"/"}>
                  Continue Shopping
                </Link>
              </div>
            </div>
          )}
        </div>

        {/* cart message section */}
        <CartMessage message={""} />

        {/* cart header section */}

        <CartHeader />

        {/* cart body section */}
        {user && isLoading ? (
          // show loading spinner while cart data is loading
          <Loader />
        ) : lineItems?.length > 0 ? (
          // show cart items if there are any
          <div className="bc-cart-body">
            <StandardItems
              lineItems={lineItems}
              lowerInventoryLineItems={lowerInventoryLineItems}
              cartType={cartType}
              register={register}
            />
            <div className="cart-footer-section">
              {/* cart footer section */}
              <CartFooter
                cartType={cartType}
                cart={cart}
                lineItemsLength={lineItems.length}
                removeItems={removeItems}
                isCheckoutDisable={
                  lowerInventoryLineItems.length ? true : false
                }
              />
            </div>
          </div>
        ) : !user ? (
          // prompt user to login if they are not logged in
          <div className="bc-cart__empty">
            <p className="bc-cart__title--empty">
              Login to see the items you added previously.
            </p>
            <Button to="/login?redirect_to=/cart">Login</Button>
          </div>
        ) : (
          <div className="bc-cart__empty">
            <h4 className="bc-cart__title--empty">Your cart is empty.</h4>
            <Link to="/products" className="bc-cart__continue-shopping link">
              Take a look around.
            </Link>
          </div>
        )}
      </section>
    </>
  );
};

Cart.propTypes = {
  cartType: PropTypes.string,
};

export default Cart;
