import useSWR from "swr";
import { useSelector } from "react-redux";
import Api from "../../services/Api";
import { cuatomerAddressesURL } from "../../ApiEndpoints";

function useGetAddress() {
  const { user } = useSelector((state) => state.auth);
  const customerId = user?.["customer_id"];

  const { data, error, mutate } = useSWR(
    customerId ? cuatomerAddressesURL(customerId) : null,
    getAddresses
  );

  const isLoading = !error && !data;

  return {
    addresses: data || [],
    isLoading,
    mutate,
  };
}

async function getAddresses(url) {
  const response = await Api.get(url);
  return response.data.data;
}

export default useGetAddress;
