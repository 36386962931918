import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import ButtonLoader from "../form/ButtonLoader";
import CurrencyFormatter from "./CurrencyFormatter";
import { CartContext } from "../../context/CartContext";
import useToggle from "../../hooks/useToggle";
import SaveYourCartForm from "./SaveYourCartForm";
import ReactModal from "../form/Dialog/NewModal";
import useCustomerGroupRestriction from "../../hooks/customer/useCustomerGroupRestriction";
import ClearCartConfirmModal from "./ClearCartConfirmModal";
import useCheckout from "../../hooks/cart/useCheckout";
import useGetAddress from "../../hooks/cart/useGetAddress";

function CartFooter({
  cartType,
  cart,
  lineItemsLength,
  removeItems,
  isCheckoutDisable,
}) {
  function calculateShipping(cartAmount) {
    if (cartAmount >= 15000) {
      return 0;
    } else if (cartAmount > 5000) {
      const freight = 225;
      const groundShipping = cartAmount * 0.045;
      return { freight, groundShipping };
    } else {
      return cartAmount * 0.03;
    }
  }

  const shippingCharge = calculateShipping(cart?.["cart_amount"] || 0);
  const [selectedValue, setSelectedValue] = useState(
    shippingCharge?.groundShipping || shippingCharge || 0
  );

  useEffect(() => {
    if (typeof shippingCharge !== "object") {
      setSelectedValue(shippingCharge);
    }
  }, [shippingCharge]);

  const { isCheckoutLoading, handleCheckoutClick } = useCheckout();
  const { addresses } = useGetAddress();
  const [isOpen, toggleDialog] = useToggle(false);
  const { clearCart, checkoutLoading } = useContext(CartContext);
  const { isCustomerRestricted } = useCustomerGroupRestriction();
  const [open, setOpen] = useState(false);
  const [billingAddress, setBillingAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const onClickClearCart = () => {
    setOpen(true);
  };

  const onCancelClearCart = () => {
    setOpen(!open);
  };
  const onCheckout = () => {
    const payload = {
      billing_address: billingAddress,
      shipping_address: shippingAddress,
      payment_method: paymentMethod,
      shipping_cost: selectedValue,
    };
    handleCheckoutClick(false, payload);
  };
  const isButtonDisabled =
    !billingAddress || !shippingAddress || !paymentMethod;

  const onRadioChange = (e) => {
    setSelectedValue(parseFloat(e.target.value)); // Update selected shipping value
  };

  // render footer only when cart type is 'full' ...
  if (cartType === "full") {
    return (
      <>
        {lineItemsLength > 0 && (
          <div className="cart-bottom-action-section">
            {!isCustomerRestricted ? (
              <span
                className="button button--secondary button-small"
                onClick={() => toggleDialog()}
                disabled={isCheckoutLoading || checkoutLoading}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    toggleDialog();
                  }
                }}
              >
                Save My Cart
              </span>
            ) : null}
            <div className="bc-cart-actions flex flex-wrap align-right vertical-middle">
              <span
                className="button button-border button-small"
                onClick={() => onClickClearCart()}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickClearCart();
                  }
                }}
              >
                Clear Entire cart
              </span>
              <span
                className="button button-small button-border"
                onClick={() => removeItems()}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    removeItems();
                  }
                }}
              >
                Remove Selected Items from Cart
              </span>
            </div>
          </div>
        )}

        <div className="cart-checkout-section">
          <div className="cart-checkout-inner">
            <div className="form-field">
              <label className="form-label" htmlFor="billingAddress">
                Billing Address
              </label>
              <select
                name="billingAddress"
                id="billingAddress"
                className="form-select form-select--small"
                value={billingAddress}
                onChange={(e) => setBillingAddress(e.target.value)}
              >
                <option value="">Select Billing Address</option>
                {addresses.map((address) => (
                  <option key={address.id} value={address.id}>
                    {address.address}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-field">
              <label className="form-label" htmlFor="shippingAddress">
                Shipping Address
              </label>
              <select
                name="shippingAddress"
                id="shippingAddress"
                className="form-select form-select--small"
                value={shippingAddress}
                onChange={(e) => setShippingAddress(e.target.value)}
              >
                <option value="">Select Shipping Address</option>
                {addresses.map((address) => (
                  <option key={address.id} value={address.id}>
                    {address.address}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-field">
              <label className="form-label" htmlFor="paymentMethod">
                Payment Method
              </label>
              <select
                name="paymentMethod"
                id="paymentMethod"
                className="form-select form-select--small"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <option value="">Select Payment Method</option>
                <option value="Cash on Delivery">Cash on Delivery</option>
                <option value="Pay in Store">Pay in Store</option>
              </select>
            </div>
          </div>
        </div>

        <footer className="bc-cart-footer">
          <div className="cart-totals">
            <ul className="cart-total">
              <li className="cart-grand-total">
                <div className="col">
                  <strong>{`Total:`}</strong>
                </div>
                <div className="col align-right grand-total-price">
                  <strong>
                    <CurrencyFormatter
                      amount={cart?.["cart_amount"] || 0}
                      currency={cart.currency.code}
                    />
                  </strong>
                </div>
              </li>

              {shippingCharge?.freight ? (
                <>
                  <li className="cart-grand-total">
                    <div className="col">
                      <strong>{`Shipping`}</strong>
                    </div>
                  </li>
                  <li className="flex justify-space">
                    <div className="col">
                      <input
                        className="form-check-input"
                        type="radio"
                        value={shippingCharge?.freight}
                        name="shippingOption"
                        id={"freightRadio"}
                        onChange={onRadioChange}
                        checked={selectedValue === shippingCharge?.freight}
                      />
                      <label htmlFor="freightRadio">
                        <strong>Freight</strong>
                      </label>
                    </div>
                    <div className="col align-right grand-total-price">
                      <strong>
                        <CurrencyFormatter
                          amount={shippingCharge?.freight || 0}
                          currency={cart.currency.code}
                        />
                      </strong>
                    </div>
                  </li>
                  <li className="flex justify-space">
                    <div className="col">
                      <input
                        className="form-check-input"
                        type="radio"
                        value={shippingCharge?.groundShipping}
                        name="shippingOption"
                        id={"groundRadio"}
                        onChange={onRadioChange}
                        checked={
                          selectedValue === shippingCharge?.groundShipping
                        }
                      />
                      <label htmlFor="groundRadio">
                        <strong>Ground</strong>
                      </label>
                    </div>
                    <div className="col align-right grand-total-price">
                      <strong>
                        <CurrencyFormatter
                          amount={shippingCharge?.groundShipping || 0}
                          currency={cart.currency.code}
                        />
                      </strong>
                    </div>
                  </li>
                </>
              ) : (
                <li className="cart-grand-total">
                  <div className="col">
                    <strong>{`Shipping cost:`}</strong>
                  </div>
                  {shippingCharge !== 0 ? (
                    <div className="col align-right grand-total-price">
                      <strong>
                        <CurrencyFormatter
                          amount={selectedValue}
                          currency={cart.currency.code}
                        />
                      </strong>
                    </div>
                  ) : (
                    <div className="col align-right grand-total-price">
                      <strong>{"FREE"}</strong>
                    </div>
                  )}
                </li>
              )}

              <li className="cart-grand-total">
                <div className="col">
                  <strong>{`Grand Total:`}</strong>
                </div>
                <div className="col align-right grand-total-price">
                  <strong>
                    <CurrencyFormatter
                      amount={selectedValue + (cart?.["cart_amount"] || 0)}
                      currency={cart.currency.code}
                    />
                  </strong>
                </div>
              </li>
            </ul>
          </div>

          {lineItemsLength > 0 && (
            <div className="bc-cart-actions bc-cart-actions-bottom flex flex-wrap align-right vertical-middle">
              {!isCustomerRestricted ? (
                <div className="cart-col-action">
                  {isCheckoutLoading || checkoutLoading ? (
                    <ButtonLoader />
                  ) : (
                    <button
                      className="bc-btn bc-cart-actions__checkout-button button button-large"
                      onClick={() => onCheckout()}
                      disabled={isCheckoutDisable || isButtonDisabled}
                    >
                      Proceed to Checkout
                    </button>
                  )}
                </div>
              ) : null}
              {isOpen && (
                <ReactModal
                  isOpen={isOpen}
                  setIsOpen={() => toggleDialog()}
                  title={"Save your cart"}
                >
                  <SaveYourCartForm onClose={toggleDialog} />
                </ReactModal>
              )}

              {open && (
                <ReactModal
                  isOpen={open}
                  setIsOpen={onCancelClearCart}
                  title="Confirm Clear Cart"
                >
                  <ClearCartConfirmModal
                    clearCart={clearCart}
                    onClose={onCancelClearCart}
                  />
                </ReactModal>
              )}
            </div>
          )}
        </footer>
      </>
    );
  } else {
    return null;
  }
}

CartFooter.propTypes = {
  isCheckoutDisable: PropTypes.bool,
  cartType: PropTypes.string,
  cart: PropTypes.object,
  lineItemsLength: PropTypes.number,
  removeItems: PropTypes.func,
};

export default CartFooter;
