import { useState, useEffect, useContext } from "react";
import { checkoutURL, customCheckoutURL } from "../../ApiEndpoints";
import { useSelector } from "react-redux";
import useSetCheckoutIframe from "./useSetCheckoutIframe";
import Api from "../../services/Api";
import { mutate } from "swr";
import { navigate } from "gatsby";
import useToastify from "../ui/useToastify";
import { CartContext } from "../../context/CartContext";

function useCheckout() {
  const { user } = useSelector((state) => state.auth);
  const initialCartId = user?.["cart_id"];
  const { toastMessage } = useToastify();
  const { clearCart } = useContext(CartContext);
  const [isCheckoutLoading, setCheckoutLoading] = useState(false);
  const [cartId, setCartId] = useState(null);

  const checkoutIframe = document.querySelector("#checkout-iframe");

  const { setIframe } = useSetCheckoutIframe();

  useEffect(async () => {
    if (!cartId || !checkoutIframe) {
      console.log("first time ?");
      setCheckoutLoading(true);
      const checkoutURL = await checkoutCart(false);
      await setIframe(checkoutURL);
      handleDelayedUpdate(false);
    }
  }, []);

  const handleDelayedUpdate = (val) => {
    setTimeout(() => {
      setCheckoutLoading(val);
    }, 1500);
  };

  const checkoutCart = async (viaButton) => {
    try {
      const res = await getCheckoutUrl(checkoutURL(initialCartId));

      // when data is conflicting...
      if (res?.response?.status === 409) {
        // mutate cart...
        mutate("/cart");
      }

      if (res.status === 200) {
        const checkout_url = res?.data?.checkout_url;

        if (cartId !== res?.data?.cart_id || !checkoutIframe) {
          console.log(res.data.cart_id);
          setIframe(checkout_url);
          setCartId(res.data.cart_id);
        }

        if (viaButton) {
          const changeCartPageStyle = () => {
            const checkoutIframe = document.querySelector("#checkout-iframe");

            checkoutIframe.style.width = "100%";
            checkoutIframe.style.minHeight = "100vh";
            checkoutIframe.style.overflow = "auto";
          };
          changeCartPageStyle();
          navigate("/checkout");
        } else {
          return checkout_url;
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const customCheckoutCart = async (payload) => {
    setCheckoutLoading(true);

    try {
      const res = await getCustomCheckoutUrl(
        customCheckoutURL(initialCartId),
        payload
      );
      if (res.status === 200) {
        clearCart();
        mutate("/cart");
        toastMessage("success", "Order placed successfully!");
        setCheckoutLoading(false);
      }
    } catch (e) {
      console.error(e);
      toastMessage("success", "Somthing went wrong");
      setCheckoutLoading(false);
    }
  };

  async function handleCheckoutClick(checkout, payload) {
    if (checkout) {
      await checkoutCart(true);
    } else {
      await customCheckoutCart(payload);
    }
  }

  return {
    isCheckoutLoading,
    setCheckoutLoading,
    handleCheckoutClick,
  };
}

async function getCheckoutUrl(url) {
  const response = await Api.post(url);
  return response;
}

async function getCustomCheckoutUrl(url, data) {
  const response = await Api.post(url, data);
  return response;
}
export default useCheckout;
